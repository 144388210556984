import React, { Component } from 'react'
    
class Avaliacao extends Component {
    componentDidMount() {
        window.location.replace("https://g.page/r/CSqFsylaVpYbEBM/review");
    }

    render() {
        return <div />
    }
}

export default Avaliacao